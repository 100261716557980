<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="!admissionExaminationRoomId">
              <b-col cols="5">
                <b-row v-if="!admissionExaminationRoomId">
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="1.Đợt tuyển sinh:"
                      label-for="admissionRoundSelected"
                    >
                      <v-select
                        v-model="admissionRoundSelected"
                        :options="admissionRounds"
                        :reduce="(option) => option.value"
                        :disabled="!!admissionExaminationRoomId"
                        :clearable="false"
                        placeholder="1.Đợt tuyển sinh"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="2.Phương thức tuyển sinh:"
                      label-for="admissionFormSelected"
                    >
                      <v-select
                        v-model="admissionFormSelected"
                        :options="dataListAdmissionForm"
                        :reduce="(option) => option.value"
                        :disabled="!!admissionExaminationRoomId"
                        :clearable="false"
                        placeholder="2.Phương thức tuyển sinh"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row v-if="!admissionExaminationRoomId">
                  <b-col cols="12">
                    <b-form-group
                      label-cols="2"
                      label-cols-lg="2"
                      label="3.Môn thi:"
                      label-for="admissionSubjectSelected"
                    >
                      <v-select
                        v-model="admissionSubjectSelected"
                        :options="admissionSubjectOptions"
                        :reduce="(option) => option.value"
                        :disabled="!!admissionExaminationRoomId"
                        :clearable="false"
                        placeholder="3.Chọn môn thi:"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="2"
                      label-cols-lg="2"
                      label="4.Phòng thi:"
                      label-for="admissionExaminationRoomSubjectsSelected"
                    >
                      <v-select
                        v-model="admissionExaminationRoomSubjectsSelected"
                        :options="admissionExaminationRoomSubjectsOptions"
                        :reduce="(option) => option.value"
                        :disabled="!!admissionExaminationRoomId"
                        :clearable="false"
                        placeholder="4.Chọn phòng thi"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <validation-observer
              ref="studentExaminationPointFormRef"
              #default="{invalid}"
            >
              <b-row>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-show="filter.admissionExaminationRoomId !== 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-right: 5px"
                      @click="onSelectStudentExaminationRoom"
                    >
                      <span class="text-nowrap text-right">Danh sách phách</span>
                    </b-button>
                    <b-button
                      v-show="codeByAdmissionExaminationRooms.length > 0 && filter.admissionExaminationRoomId !== 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="invalid"
                      @click="onSave(codeByAdmissionExaminationRooms)"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="CheckIcon" /> Lưu lại
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="codeByAdmissionExaminationRooms"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                    class="my-table"
                    style-class="my-table vgt-table striped bordered"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'score'">
                        <validation-provider
                          #default="{ errors }"
                          rules="required|between:0,10"
                          name="Điểm"
                        >
                          <b-form-input
                            v-model="props.row.score"
                            :tabindex="props.row.originalIndex + 1"
                            @change="
                              changeCell(
                                props.row.score,
                                props.column.field,
                                props.row.originalIndex
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </span>
                      <span v-else-if="props.column.field === 'note'">
                        <b-form-input
                          v-model="props.row.note"
                          type="text"
                          @change="
                            changeCell(
                              props.row.note,
                              props.column.field,
                              props.row.originalIndex
                            )
                          "
                        />
                      </span>
                      <!-- Column: Common -->
                      <span v-else>{{
                        props.formattedRow[props.column.field]
                      }}</span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BOverlay,
  BRow,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@core/utils/validations/validations'
import { getUser } from '@/auth/utils'
import c from 'v-select2-component'

export default {
  name: 'StudentExaminationPoint',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    VueGoodTable,
    vSelect,
    BFormInput,
  },
  props: {
    admissionExaminationRoomId: {
      type: Number,
      default: null,
    },
    admissionRoundId: {
      type: Number,
      default: null,
    },
    admissionFormId: {
      type: Number,
      default: null,
    },
    admissionSubjectId: {
      type: Number,
      default: null,
    },
    subjectCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      admissionRoundSelected: undefined,
      admissionFormSelected: undefined,
      admissionExaminationRoomSubjectsSelected: undefined,
      admissionSubjectSelected: undefined,
      isLoading: false,
      filter: {
        admissionExaminationRoomId: 0,
      },
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Số phách',
          field: 'headerCode',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm bằng số',
          field: 'score',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      studentExaminationRoomsByHeaderCode: [],
      required,
      between,
    }
  },
  computed: {
    ...mapGetters({
      codeByAdmissionExaminationRooms: 'applicantStudentExaminationRoom/codeByAdmissionExaminationRooms',
      examinationRoomSubjects: 'applicantStudentExaminationRoom/examinationRoomSubjects',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionExaminationRoom/admissionForms',
      listAdmissionSubject: 'admissionExaminationRoom/listAdmissionSubject',
    }),
    admissionSubjectOptions() {
      return this.listAdmissionSubject.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    admissionExaminationRoomSubjectsOptions() {
      return this.examinationRoomSubjects.map(item => ({ value: item.id, label: item.name }))
    },
    examinationPointUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_EXAMINATION_POINT)
    },
  },
  watch: {
    async admissionRoundSelected(val) {
      this.admissionFormSelected = null
      this.admissionExaminationRoomSubjectsSelected = null
      this.admissionSubjectSelected = null
      if (!val) {
        this.reset()
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_FORMS', { data: [] })
        this.$store.commit('applicantStudentExaminationRoom/SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: [] })
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_SUBJECT', { data: [] })
      } else {
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: val })
        if (this.admissionFormId) {
          const found = this.dataListAdmissionForm.find(element => +element.id === +this.admissionFormId)
          if (found) this.admissionFormSelected = this.admissionFormId
        }
      }
    },
    async admissionFormSelected(val) {
      this.admissionExaminationRoomSubjectsSelected = null
      this.admissionSubjectSelected = null
      if (!val) {
        this.reset()
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_SUBJECT', { data: [] })
        this.$store.commit('applicantStudentExaminationRoom/SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: [] })
      } else {
        await this.getAdmissionSubject({
          admissionRoundId: this.admissionRoundSelected,
          admissionFormId: this.admissionFormSelected,
        })
        if (this.subjectCode) {
          const found = this.listAdmissionSubject.find(e => e.code === this.subjectCode)
          if (found) {
            this.admissionSubjectSelected = this.subjectCode
          }
        }
      }
    },
    async admissionSubjectSelected(val) {
      this.admissionExaminationRoomSubjectsSelected = null
      if (!val) {
        this.reset()
        this.$store.commit('applicantStudentExaminationRoom/SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: [] })
      } else {
        await this.getExaminationRoomSubjects({
          admissionRoundId: this.admissionRoundSelected,
          admissionFormId: this.admissionFormSelected,
          admissionSubjectId: this.admissionSubjectSelected,
        })
        if (this.admissionExaminationRoomId) {
          const found = this.examinationRoomSubjects.find(element => +element.id === this.admissionExaminationRoomId)
          if (found) this.examinationRoomSubjectsSelected = this.admissionExaminationRoomId
        }
      }
    },
    async admissionExaminationRoomSubjectsSelected(val) {
      if (!val) {
        this.reset()
      } else {
        this.filter.admissionExaminationRoomId = this.admissionExaminationRoomSubjectsSelected
        await this.getCodeByAdmissionExaminationRooms(this.filter)
      }
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      if (this.admissionRoundId) {
        this.admissionRoundSelected = this.admissionRoundId
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getCodeByAdmissionExaminationRooms: 'applicantStudentExaminationRoom/getCodeByAdmissionExaminationRooms',
      readAdmissionFormByAdmissionRoundId: 'admissionExaminationRoom/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getAdmissionSubject: 'admissionExaminationRoom/getAdmissionSubject',
      getExaminationRoomSubjects: 'applicantStudentExaminationRoom/getExaminationRoomSubjects',
      saveScoresByCode: 'applicantStudentExaminationRoom/saveScoresByCode',
      exportPdfScoreHeaderCode: 'applicantStudentExaminationRoom/exportPdfScoreHeaderCode',
    }),
    reset() {
      this.filter.admissionExaminationRoomId = 0
      this.$store.commit(
        'applicantStudentExaminationRoom/SET_CODE_BY_ADMISSION_EXAMINATION_ROOMS',
        { data: [] },
      )
    },
    async onSelectStudentExaminationRoom() {
      this.isLoading = true
      try {
        await this.getCodeByAdmissionExaminationRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    changeCell(data, column, row) {
      this.codeByAdmissionExaminationRooms[row][column] = data
    },
    async onSave(data) {
      if (!this.examinationPointUpdatable) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn không có quyền nhập điểm thi!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score === null || element.score === undefined)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đầy đủ dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      // eslint-disable-next-line no-restricted-globals
      if (data.some(element => isNaN(element.score))) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đúng kiểu dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score < 0 || element.score > 10)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Điểm chỉ được nằm trong khoảng từ 0 đến 10!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      const scoresByCode = data.map(element => ({
        id: element.id,
        score: element.score,
        note: element.note || null,
        applicantStudentId: element.applicantStudentId,
      }))
      try {
        const response = await this.saveScoresByCode({
          scoresByCode,
          admissionExaminationRoomId: this.admissionExaminationRoomSubjectsSelected,
          admissionFormId: this.admissionFormSelected,
          admissionSubjectId: this.admissionSubjectSelected,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await Promise.all([
              this.getCodeByAdmissionExaminationRooms(this.filter),
              this.exportPdfFile(),
            ])
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfFile() {
      try {
        const data = await this.exportPdfScoreHeaderCode({
          admissionExaminationRoomId: this.admissionExaminationRoomSubjectsSelected,
          score: 1, // Hiện điểm
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
